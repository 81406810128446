.cateList {
  list-style: none;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}

.industry-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.industry-link {
  text-decoration: none;
  color: #000;
  font-size: 14px;
}

.expanded {
}

.toggle-icon {
  font-size: 14px;
  color: #043289;
  margin-right: 20px;
  margin-left: 20px;
}

.category-list {
  margin-bottom: 10px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
  padding-left: 10px;
}

.category-list.expanded {
  overflow: visible;
  max-height: none;
}

.category-link {
  text-decoration: none;
  color: #555;
}
